import React from 'react';
import { PageProps } from 'gatsby';
import omit from 'lodash/omit';
import classNames from 'classnames';

import data from '~/data/case-studies/hippo';
import { ProjectDTO } from '~/types';
import Layout from '~/components/Layout';
import ProjectHeader from '~/components/ProjectHeader';
import ClientDescription, {
  ClientDescriptionData,
} from '~/components/ClientDescription';
import ProjectExcerpt, {
  ProjectExcerptData,
} from '~/components/ProjectExcerpt';
import EstimationCTA from '~/components/EstimationCTA';
import ScopeDescription, {
  ScopeDescriptionData,
} from '~/components/ScopeDescription';
import Keywords from '~/components/Keywords';
import Image from '~/components/Image';
import OtherProjects from '../components/OtherProjects';

import styles from './Hippo.module.scss';

const Hippo: React.FC<PageProps> = ({ location }) => {
  const { generalInfo, client, scope, tldr, platform } = data;

  const projectHeaderData: ProjectDTO = {
    ...omit(generalInfo, 'nameShort'),
    img: data.generalInfo.img,
  };

  const clientDescriptionData: ClientDescriptionData = {
    textShort: client.descriptionShort,
    textLong: client.descriptionLong,
    scope: scope.types,
    techStack: scope.techStack,
  };

  const projectExcerptData: ProjectExcerptData = { text: tldr };

  const scopeDescriptionData: ScopeDescriptionData = {
    textShort: scope.descriptionShort,
    textLong: scope.descriptionLong,
  };

  return (
    <Layout invertNavigation>
      <header className={styles.header}>
        <ProjectHeader
          data={projectHeaderData}
          imgContainerClass={styles.headerImgContainer}
        />
      </header>
      <article className={styles.article}>
        <ClientDescription data={clientDescriptionData} />
      </article>

      <article className={styles.platform}>
        <div className={styles.imgContainer}>
          <Image
            img={platform.images.img}
            imgMobile={platform.images.imgMobile}
            alt={generalInfo.name}
            className={styles.img}
          />
        </div>
        <Keywords
          label={platform.label}
          keywords={platform.types}
          className={styles.keyword}
        />
      </article>

      <article className={styles.articleXl}>
        <ProjectExcerpt data={projectExcerptData} />
      </article>
      <article className={styles.article}>
        <ScopeDescription data={scopeDescriptionData} />
      </article>
      <article className={styles.article}>
        <div className={styles.imgContainer}>
          {scope.images.map(({ img, imgMobile }, index) => (
            <Image
              key={index}
              img={img}
              imgMobile={imgMobile}
              alt={generalInfo.name}
              className={classNames(styles.img)}
            />
          ))}
        </div>
      </article>
      <article className={styles.cta}>
        <EstimationCTA />
      </article>
      <article>
        <OtherProjects curretPageUrl={location.pathname} />
      </article>
    </Layout>
  );
};

export default Hippo;
