import { ProjectCategory } from '~/enums';

import hippoMain from './assets/hippo.jpg';
import hippoMainMobile from './assets/hippo-mobile.jpg';
import hippoApp1 from './assets/hippo-app-1.jpg';
import hippoApp1Mobile from './assets/hippo-app-1-mobile.jpg';
import hippoApp2 from './assets/hippo-app-2.jpg';
import hippoApp2Mobile from './assets/hippo-app-2-mobile.jpg';
import hippoApp3 from './assets/hippo-app-3.jpg';
import hippoApp3Mobile from './assets/hippo-app-3-mobile.jpg';
import routes from '~/routes';

const hippoGeneralInfo = {
  name: 'Hippo Bloat',
  nameShort: 'Hippo',
  category: ProjectCategory.INSURANCE,
  summary: 'Improving the home buying & selling process',
  img: hippoMain,
  imgMobile: hippoMainMobile,
  projectPageUrl: routes.CASE_STUDIES_HIPPO,
  description: '',
};

const hippoCaseStudy = {
  generalInfo: hippoGeneralInfo,
  client: {
    descriptionShort:
      'Valued at over 1 billion dollars, Hippo is a company that reinvents the property insurance market in the US.',
    descriptionLong:
      'Thanks to the use of machine learning it can offer potential clients quotes in 60 seconds. The whole process of getting a policy takes only a few minutes and can be done 100% online. Properties and assets insured by Hippo are worth 50 billion dollars in total.',
  },
  scope: {
    types: ['Development'],
    techStack: ['TypeScript', 'NestJS', 'React'],
    descriptionShort:
      'Hippo Bloat provides clients financial protection tied to a home inspection. We were given a task of building the web app for Bloat as fast as possible.',
    descriptionLong:
      '<p>The pilot program run by Hippo turned out to be <b>very successful</b>, and the company didn’t want to lose clients’ interest.</p><p>The biggest challenge for developers was to choose the most optimal solutions to ensure that the project will be <b>finished on time</b>, but also reliable and secure. An external audit confirmed that chosen technologies were in fact the most suitable ones for the situation.</p>',
    images: [
      { img: hippoApp2, imgMobile: hippoApp2Mobile },
      { img: hippoApp3, imgMobile: hippoApp3Mobile },
    ],
  },
  platform: {
    label: 'Platform',
    types: ['Web'],
    images: {
      img: hippoApp1,
      imgMobile: hippoApp1Mobile,
    },
  },
  tldr:
    'We created a web app for a disruptive insurance company. We finished on time and the client was happy to expand our partnership to a couple of other projects.',
};

export default hippoCaseStudy;
