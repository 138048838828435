import React from 'react';

import styles from './ScopeDescription.module.scss';

export type ScopeDescriptionData = {
  title?: string;
  textShort: string;
  // String that can include <p> and <b> html tags
  textLong: string;
};

type Props = {
  data: ScopeDescriptionData;
};

const ScopeDescription: React.FC<Props> = ({ data }) => {
  const { title = 'Our work', textShort, textLong } = data;

  return (
    <div className={styles.root}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.textShort}>{textShort}</p>
      <div
        className={styles.textLong}
        dangerouslySetInnerHTML={{ __html: textLong }}
      />
    </div>
  );
};

export default ScopeDescription;
